<div class="wrap component-wrap">
  <div class="buttons disp-flex">
    <div class="button-wrap">
      <button (click)="setState(TEXT_TO_3D)" [ngClass]="{ active: state === TEXT_TO_3D }" type="button"
        mat-raised-button class="btn btn-g large small-text">
        <!-- <img
          src="/assets/images/text.svg"
          alt="text"
          class="va-middle"
          [ngClass]="{ invert: state === TEXT_TO_3D }"
        /> -->
        Text to 3D
      </button>
    </div>
    <div class="button-wrap">
      <button (click)="setState(IMAGE_TO_3D)" [ngClass]="{ active: state === IMAGE_TO_3D }" type="button"
        mat-raised-button class="btn btn-g large small-text">
        <!-- <img
          src="/assets/images/picture.svg"
          alt="picture"
          class="va-middle"
          [ngClass]="{ invert: state === IMAGE_TO_3D }"
        /> -->
        Image{{generateService.allowMultipleImages ? '(s)' : ''}} to 3D
      </button>
    </div>
  </div>
  <div class="generate-by-type space-top">
    @switch (state) { @case(TEXT_TO_3D) {
    <div class="space-bottom-xs">
      <div class="disp-flex">
        <span class="txt-xxs bold capitalize-fl">insert prompt</span>
        <a href="javascript:void(0)" class="align-end-flex link txt-xxs bold" (click)="examplePrompt()">example
          prompt</a>
      </div>
      <mat-form-field class="w-100 ta-wrap">
        <textarea rows="6" [(ngModel)]="generateService.text"
          placeholder="dragon wings and unicorn head hybrid creature, highly detailed..." matInput></textarea>
      </mat-form-field>
    </div>
    } @case(IMAGE_TO_3D) {
    <div>
      <div class="creation-name txt-xxs bold" [ngClass]="{ 'warn-color': imageToUpload[0] && !generateService.text }">
        <span class="mandatory">*</span> Creation name
      </div>
      <div>
        <mat-form-field class="w-100">
          <input placeholder="Insert creation name" matInput [(ngModel)]="generateService.text" />
        </mat-form-field>
      </div>
    </div>
    <div class="pos-rel">
      @if (imageToUpload[0]) {
      <div class="prompt-img pos-rel">
        <div class="img-scroll pos-abs"
          [ngClass]="{'w-100': !generateService.allowMultipleImages || imageToUpload.length === 1}">
          @for (img of imageToUpload; track $index) {
          <img [src]="img" alt="image to 3D" />
          }
        </div>
      </div>
      <button type="button" class="delete-image pos-abs" mat-icon-button (click)="imageToUpload = []">
        <mat-icon>clear</mat-icon>
      </button>
      } @else {
      <div class="txt-xxs bold capitalize-fl space-bottom-sm">upload image{{generateService.allowMultipleImages ? 's' :
        ''}}</div>
      <div class="file-wrap disp-flex space-bottom-lg">
        <input placeholder="drop image here" type="file" class="poiner" accept="image/png, image/jpeg"
          [multiple]="generateService.allowMultipleImages" (change)="onFilesChange($event)" />
        <div class="file-wrap-content text-center disp-flex col">
          <img src="/assets/images/upload.svg" placeholder="select image" alt="upload file" />
          <div class="file-wrap-text disp-flex col">
            <div class="txt-sm">Upload Image{{generateService.allowMultipleImages ? 's' : ''}}</div>
            <div class="txt-xxs">jpg, jpeg, png, webp</div>
          </div>
        </div>
      </div>
      }
      @if (uploadingImage) {
      <mat-progress-bar class="img-loading pos-abs" mode="indeterminate"></mat-progress-bar>
      }
    </div>
    } }
    <div>
      @if (state === IMAGE_TO_3D) {
      <button (click)="generate()" type="button" mat-raised-button class="btn btn-a large w-100"
        [disabled]="isDisable()">
        <img src="/assets/images/cube.svg" alt="text" class="va-middle"
          [ngClass]="{ invert: state === TEXT_TO_3D, 'filter-disabled': isDisable() }" />
        Generate 3D model
      </button>
      @if (actionDetails) {
      <app-cost-est [cost]="actionDetails.credits" [est]="actionDetails.estimated_duration"
        class="disp-block"></app-cost-est>
      }
      } @else {
      <!-- <app-options-button
        [current-key]="allTextOptions[0].key"
        [options]="allTextOptions"
        (onClick)="onTextGen($event)"
        (onChange)="onTypeChange($event)"
        [disabled]="isDisable()"
        css-class="btn btn-a"
      ></app-options-button> -->
      <button (click)="generateImages()" type="button" mat-raised-button class="btn btn-a large w-100"
        [disabled]="isDisable()">
        <img src="/assets/images/picture.svg" alt="picture" class="va-middle"
          [ngClass]="{ invert: state === IMAGE_TO_3D, 'filter-disabled': isDisable() }" />
        Generate preview images
      </button>
      <!-- <app-or-separator>or</app-or-separator>
      <button
        (click)="generateFromText()"
        type="button"
        mat-raised-button
        class="btn btn-a w-100"
        [disabled]="isDisable()"
      >
        generate 3D from text
      </button> -->
      @if (actionDetails) {
      <app-cost-est [cost]="actionDetails.credits" [est]="actionDetails.estimated_duration"
        class="disp-block"></app-cost-est>
      }
      }
    </div>
  </div>
</div>