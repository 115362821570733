import { Component } from '@angular/core';
import { UserProfileComponent } from '../auth/user-profile/user-profile.component';
import { TransactionsResolve } from '../auth/user-profile/transactions.resolve';
import { ActivatedRoute } from '@angular/router';
import { IReconstructionTransaction } from '../pricing/pricing';
import { SeoService } from '../shared/seo/seo.service';
import { MetaOptions } from '../shared/seo/seo';
import { PlatformName } from '../shared/constants';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-user-profile-wrap',
    imports: [UserProfileComponent],
    templateUrl: './user-profile-wrap.component.html',
    styleUrl: './user-profile-wrap.component.scss',
    providers: [TransactionsResolve]
})
export class UserProfileWrapComponent {
  public transactions: Array<IReconstructionTransaction>;
  constructor(private activatedRoute: ActivatedRoute, private seo: SeoService) {
    this.transactions =
      this.activatedRoute.snapshot.data[
        'transactions'
      ].data.allReconstructionTransactions.rows;
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | User Profile`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, User Profile`;
    mOptions.description = `${mOptions.description} On this page you can manage your personal user profile.`;
    mOptions.canonical = `${environment.domain}topups`;
    this.seo.setMetaDate(mOptions);
  }
}
