import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PlatformName, StrongPasswordRegx } from '../../shared/constants';
import { RestService } from '../../communication/rest.service';
import { Credentials, User } from '../user';
import { UtilsService } from '../../shared/utils.service';
import { AuthService } from '../auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ScreenNotificationType, IAuthType } from '../../shared/enums';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TermsComponent } from '../terms/terms.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { SeoService } from '../../shared/seo/seo.service';
import { MetaOptions } from '../../shared/seo/seo';
import { QrViewComponent } from '../qr-view/qr-view.component';
import { BallsComponent } from '../../shared/balls/balls.component';
import { Subscription } from 'rxjs';
import { SsoOptionsComponent } from '../sso-options/sso-options.component';
import { environment } from '../../../environments/environment';
import { RecaptchaService } from '../../shared/recaptcha.service';

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password1 === control.value.password2
    ? null
    : { PasswordNoMatch: true };
};

@Component({
    selector: 'app-login',
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        CommonModule,
        MatInputModule,
        MatButtonModule,
        RouterLink,
        BallsComponent,
        SsoOptionsComponent,
    ],
    providers: [MatFormFieldModule],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', Validators.email),
    password: new FormControl<string>('', {
      validators: [Validators.required, Validators.pattern(StrongPasswordRegx)],
    }),
    confirm_password: new FormControl<string>('', {
      validators: confirmPasswordValidator,
    }),
    rememberMe: new FormControl<boolean>(true),
    code: new FormControl<string>(''),
    name: new FormControl<string>(''),
  });
  public fpForm: FormGroup;
  public twoFAForm: FormGroup;
  public screen: string;
  public fPmeg: string;
  public qrImg: SafeResourceUrl;
  private token: string;
  public isShowPassword: boolean;
  public backStack: Array<string> = [];
  public StrongPasswordRegx = StrongPasswordRegx;
  public isShowConfirmPassword: boolean;
  private loginTypeSubscription: Subscription;
  private _sendingCode: boolean;
  // public enforceOldPass: boolean;
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private recaptchaService: RecaptchaService,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private seo: SeoService
  ) {
    document.body.classList.add('gradient2');
    this.screen = this.activatedRoute.snapshot.url[0].path;
    this.backStack.push(this.screen);
    // this.enforceOldPass = this.activatedRoute.snapshot.params['enforce'] === 'true';
    // this.loginForm = new FormGroup({
    //   email: new FormControl<string>('', Validators.email),
    //   password: new FormControl<string>('', {
    //     validators: [Validators.required, Validators.pattern(StrongPasswordRegx)],
    //   }),
    //   confirm_password: new FormControl<string>('', { validators: confirmPasswordValidator }),
    //   rememberMe: new FormControl<boolean>(true),
    //   code: new FormControl<string>(''),
    //   name: new FormControl<string>(''),
    // });

    this.fpForm = new FormGroup({
      email: new FormControl<string>('', Validators.email),
    });

    this.twoFAForm = new FormGroup({
      code: new FormControl<Array<number>>([]),
    });

    this.loginTypeSubscription = new Subscription();
  }

  ngOnInit() {
    this.loginTypeSubscription.add(
      this.auth.authType.subscribe((loginType: IAuthType) =>
        this.setSignupType(loginType)
      )
    );
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | Sign In`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI, Sign In, Log In`;
    mOptions.description = `${mOptions.description} On this page you can log in to the system.`;
    mOptions.canonical = `${environment.domain}login`;
    this.seo.setMetaDate(mOptions);
  }

  get lf(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  private setSignupType(loginType: IAuthType) {
    if (loginType === IAuthType.SOCIAL) {
      this.auth.authType.next(IAuthType.INITIAL);
      if (this.auth.user) {
        if (this.auth.ssoState.token) {
          this.auth.user.token = this.auth.ssoState.token;
          // this.auth.storeToken(this.auth.user.token);
          // this.auth.storeUser(this.auth.user);
        }
        this.auth.onLoginSuccess(this.auth.user);
      } else if (this.auth.ssoState.sso_token) {
        if (this.auth.ssoState.qr_image) {
          this.screen = 'qr';
          this.setQrImg(this.auth.ssoState.qr_image);
        } else this.screen = 'qrcode';
      } else {
        this.screen = 'qr';
        this.setQrImg(this.auth.ssoState.qr_image);
      }
    }
  }

  private setQrImg(src: string) {
    this.qrImg =
      src.indexOf('https://') === 0
        ? src
        : this.sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/jpg;base64,${src}`
          );
  }

  signup() {
    if (!this.loginForm.valid) return;
    this.rest
      .userProfile(
        'put',
        { password: this.lf['password'].value },
        '?token=' + this.token
      )
      .subscribe({
        next: (user: User) => {
          if (user.token) this.token = user.token;
          this.login();
        },
        error: (e) => {
          this.utils.httpErrorResponseHandler(
            e,
            'failure setting new password'
          );
        },
      });
  }

  async login() {
    if (!this.loginForm.valid) return;
    let c = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
    } as Credentials;

    const token = await this.recaptchaService.execute('login');
    if (token)
      var result: User = await this.auth.login(
        'PUT',
        c,
        '?g-recaptcha-response=' + token
      );
    // No 2FA required
    if (result.token) {
    } else if (result.qr) {
      this.screen = 'qr';
      this.setQrImg(result.qr);
    } else {
      this.screen = 'qrcode';
    }
    this.backStack.push(this.screen);
    // this.recaptchaV3Service
    //   .execute('login')
    //   .subscribe(async (token: string) => {
    //     if (token)
    //       var result: User = await this.auth.login(
    //         'PUT',
    //         c,
    //         '?g-recaptcha-response=' + token
    //       );
    //     // No 2FA required
    //     if (result.token) {
    //     } else if (result.qr) {
    //       this.screen = 'qr';
    //       this.setQrImg(result.qr);
    //     } else {
    //       this.screen = 'qrcode';
    //     }
    //     this.backStack.push(this.screen);
    //   });
  }

  login2FA() {}

  isPasswordsMatch(): boolean {
    this.loginForm.controls['password'].value ==
      this.loginForm.controls['confirm_password'].value;
    return (
      this.loginForm.controls['password'].value ==
      this.loginForm.controls['confirm_password'].value
    );
  }

  async resetPassword() {
    if (!this.fpForm.valid) return;
    const token = await this.recaptchaService.execute('passwordRecovery');
    if (this.fpForm.controls['email'].value && token)
      this.sendRecoveryMail(this.fpForm.controls['email'].value, token);
    // this.recaptchaV3Service
    //   .execute('passwordRecovery')
    //   .subscribe((token: string) => {
    //     if (this.fpForm.controls['email'].value && token)
    //       this.sendRecoveryMail(this.fpForm.controls['email'].value, token);
    //   });
  }

  sendRecoveryMail(email: string, token: string) {
    this.rest
      .passwordRecovery('?email=' + email + '&g-recaptcha-response=' + token)
      .subscribe({
        next: () => this.recoveryNotification(),
        error: () => this.recoveryNotification(),
      });
  }

  recoveryNotification() {
    this.fPmeg =
      'Recovery e-mail has been sent, if we found an account associated with ' +
      this.fpForm.controls['email'].value;
    this.utils.notifyUser({
      text: this.fPmeg,
      type: ScreenNotificationType.Success,
      action: 'OK',
    });
  }

  goBack() {
    let b = this.backStack;
    if (b.length > 0) {
      b.pop();
      this.screen = b[b.length - 1];
    }
  }

  confirmReset() {
    this.rest
      .userProfile('put', null, '/qr' + '?token=' + this.token)
      .subscribe({
        next: (result: User) => {
          this.screen = 'qr';
          this.backStack.push(this.screen);
          this.qrImg = this.sanitizer.bypassSecurityTrustResourceUrl(
            'data:image/jpg;base64,' + result.qr
          );
        },
        error: (err) =>
          this.utils.httpErrorResponseHandler(err, 'failure saving profile'),
      });
  }

  enterCode() {
    this.screen = 'qrcode';
    this.backStack.push(this.screen);
  }

  qrPopup(num: number) {
    this.dialog.open(QrViewComponent, {
      width: '552px',
      //  height: '229px',
      data: {
        num: num ?? 1,
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value,
      },
    });
  }

  async sendCode() {
    if (this._sendingCode) return;
    this._sendingCode = true;
    let payload = {
      mfa_code: this.loginForm.controls['code'].value.toString(),
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
      sso_token: this.auth.ssoState?.sso_token,
    };

    if (this.token) {
      this.rest
        .userProfile('post', payload, '/qr' + '?token=' + this.token)
        .subscribe(
          (result: User) => {
            this.auth.atachRolesToUser(result);
            this.auth.onLoginSuccess(
              result as User,
              true,
              true,
              this.loginForm.controls['rememberMe'].value
            );
          },
          (err) => {
            this._sendingCode = false;
            this.utils.httpErrorResponseHandler(err, 'failure saving profile');
          }
        );
    } else {
      try {
        var result: User = await this.auth.login('POST', payload);
        this._sendingCode = false;
      } catch (err) {
        if (this.screen === 'qrcode') err = 'Code is not valid.';
        this.utils.notifyUser({
          text: err.toString(),
          type: ScreenNotificationType.Error,
          action: 'OK',
        });
        this._sendingCode = false;
        return;
      }
      this.auth.atachRolesToUser(result);
      this.auth.onLoginSuccess(
        result as User,
        true,
        true,
        this.loginForm.controls['rememberMe'].value
      );
    }
  }

  showPrivacy() {
    this.dialog.open(PrivacyComponent, {});
  }

  showTerms() {
    this.dialog.open(TermsComponent, {});
  }

  toggleFP(state: string) {
    if (this.screen === 'forgotpassword') return;
    if (this.screen != 'signup' && this.screen != 'reset') this.screen = state;
    this.backStack.push(state);
  }

  toggShowPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  // qrKeyup(e: KeyboardEvent) {
  //   (e.target as any).scrollLeft = 0;
  // }

  qrKeyup(e: KeyboardEvent) {
    if (this.fpForm.valid && this.loginForm.controls['code'].value.length > 5) {
      this.sendCode();
    }
  }

  ngOnDestroy(): void {
    this.loginTypeSubscription.unsubscribe();
    document.body.classList.remove('gradient2');
  }
}
