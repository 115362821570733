import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RolesHelperService } from './roles-helper.service';

export const CanActivateAuthGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const isloggedIn = await auth.isloggedIn();
  if (!isloggedIn)
    router.navigate(['/login']);
  return isloggedIn;
};

export const CanActivateAuthGuardChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => CanActivateAuthGuard(route, state);

export const CanActivateSuGuard: CanActivateFn = async (
  // route: ActivatedRouteSnapshot,
  // state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const roles = inject(RolesHelperService);
  if (!roles.isRoleLogedin('Super User')) {
    router.navigate(['/login']);
    return false;
  }
  return true;
};