import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RestService } from '../../communication/rest.service';
import { AuthService } from '../auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from '../roles-helper.service';
import { UtilsService } from '../../shared/utils.service';
import { GlobalsService } from '../../shared/globals.service';
import { User } from '../user';
import { ScreenNotificationType } from '../../shared/enums';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule, MatHint } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-recover-password',
    imports: [
        MatIconModule,
        MatHint,
        CommonModule,
        FormsModule,
        MatFormFieldModule,
    ],
    templateUrl: './recover-password.component.html',
    styleUrl: './recover-password.component.scss'
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  private token: string;
  private rolesFetchedSubscribe: Subscription;
  public password: string;
  public msg: string;
  public inputType: string;
  @ViewChild('recoveryForm') recoveryForm: NgForm;
  constructor(
    private activatedRoute: ActivatedRoute,
    private rest: RestService,
    private auth: AuthService,
    private router: Router,
    private broadcaster: BroadcasterService,
    private rolesHelper: RolesHelperService,
    private globals: GlobalsService,
    private utils: UtilsService
  ) {
    this.inputType = 'password';
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.data['token'] as string;
  }

  toggleSP($event: any) {
    $event.preventDefault();
    if (this.inputType == 'password') this.inputType = 'text';
    else this.inputType = 'password';
  }

  changePassword() {
    if (!this.recoveryForm.valid) return;
    this.rest
      .userProfile('put', { password: this.password }, '?token=' + this.token)
      .subscribe(
        // user => this.auth.onLoginSuccess(user as User),
        (user: User) => {
          user.token = this.token;
          this.auth.atachRolesToUser(user);
          this.auth.onLoginSuccess(user, false, false);
          // this.rolesFetchedSubscribe = this.broadcaster.on('rolesFetched').subscribe(() => {
          this.rolesFetchedSubscribe =
            this.rolesHelper.onRolesFetched.subscribe(() => {
              this.auth.onLoginSuccess(user, false);
              this.router.navigate(['/user-profile', user.id]);
              this.utils.notifyUser({
                text: 'password updated',
                type: ScreenNotificationType.Success,
                action: 'OK',
              });
              this.rolesFetchedSubscribe.unsubscribe();
            });
          this.rolesHelper.fetchRoles();
        },
        (err) =>
          this.utils.httpErrorResponseHandler(
            err,
            'failure setting new password'
          )
      );
  }

  ngOnDestroy() {
    if (this.rolesFetchedSubscribe) this.rolesFetchedSubscribe.unsubscribe();
  }
}
