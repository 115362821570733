import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ViewerService } from '../viewer.service';
import { UtilsService } from '../utils.service';
import { ViewerComponent } from '../viewer/viewer.component';

@Component({
    selector: 'app-preview',
    imports: [ViewerComponent],
    templateUrl: './preview.component.html',
    styleUrl: './preview.component.scss'
})
export class PreviewComponent implements OnInit, OnDestroy {
  @Input() url: string;
  public viewerService: ViewerService;
  constructor(private utils: UtilsService ) {
    this.viewerService = new ViewerService(this.utils);
  }

  ngOnInit() {
    this.viewerService.setViewerUrl(this.url);
  }

  ngOnDestroy() {
    this.viewerService.destroy();
  }
}
