import { Injectable } from '@angular/core';
import {
  IReconstruct,
  IReconstructImage,
  IReconstructJob,
  IReconstructJobUI,
  IReconstructionAction,
  IReconstructionJobQuery,
  ISimilarItem,
  RECONSTRUCT_JOB_STATUS,
  RECONSTRUCTION_ACTION,
  IReconstructTexture,
} from './generate';
import { UtilsService } from '../shared/utils.service';
import { RestService } from '../communication/rest.service';
import { PixelsService } from '../shared/pixels.service';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client';
import { Subject, Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import {
  ScreenNotificationType,
  IPlaygroundNotification,
  IPlaygroundNotificationType,
  PlaygroundNotificationType,
} from '../shared/enums';
import { CustomRequestOptions } from '../communication/custom-request';
import { EnumsService } from '../shared/enums.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateService {
  private _creation: IReconstructJobUI;
  private _createdImages: Array<string>;
  private _createdImagesIndex: number;
  private _reconstructedImages: Array<IReconstructImage>;
  private _reconstructedImage: IReconstructImage;
  private _similarItems: Array<ISimilarItem>;
  private _fetchingSimilar: boolean;
  private _text: string;
  private _subs: Array<Subscription>;
  private _isSubscribed: boolean;
  private _similarDictionary: { [id: string]: Array<ISimilarItem> };
  private _allowMultiple: boolean;
  public onCreationPush: Subject<IPlaygroundNotification>;
  // public latestUpdated: IReconstructJobUI;
  public counter: number;
  constructor(
    private utils: UtilsService,
    private rest: RestService,
    private pixels: PixelsService,
    private gql: GraphqlService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService,
    private auth: AuthService
  ) {
    this._allowMultiple = true;
    this.counter = 0;
    this._subs = [];
    this._similarDictionary = {};
    this.onCreationPush = new Subject<IPlaygroundNotification>();
    this.subscribe();
  }

  get text() {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get creation() {
    return this._creation;
  }

  set creation(value: IReconstructJobUI) {
    this._creation = value;
  }

  get createdImages() {
    return this._createdImages;
  }

  set createdImages(value: Array<string>) {
    this._createdImages = value;
  }

  get similarItems() {
    return this._similarItems;
  }

  set similarItems(value: Array<any>) {
    this._similarItems = value;
  }

  get createdImagesIndex() {
    return this._createdImagesIndex;
  }

  set createdImagesIndex(value: number) {
    this._createdImagesIndex = value;
  }

  public get reconstructedImages() {
    return this._reconstructedImages;
  }

  public set reconstructedImages(
    reconstructedImages: Array<IReconstructImage>
  ) {
    this._reconstructedImages = reconstructedImages;
  }

  public get reconstructedImage() {
    return this._reconstructedImage;
  }

  public set reconstructedImage(reconstructedImage: IReconstructImage) {
    this._reconstructedImage = reconstructedImage;
  }

  public get fetchingSimilar(): boolean {
    return this._fetchingSimilar;
  }

  public get allowMultipleImages() {
    return this._allowMultiple;
  }

  imageTo3D(payload: IReconstruct): Promise<IReconstructJob> {
    return new Promise(async (resolve: any, reject: any) => {
      this.pixels.sendPixel({
        event: 'click',
        // button_name: 'generate preview image',
        click_type: 'generate_preview_image',
      });
      if (payload.text) payload.text = payload.text.trim();
      this.creation = (await this.utils.observableToPromise(
        this.rest.reconstruct('POST', payload)
      )) as IReconstructJobUI;
      this.afterAction();
      this.creation._delayEnter = 1;
      // this.latestUpdated = res;
      this.createdImages = null;
      this.createdImagesIndex = 0;
      resolve(this.creation);
    });
  }

  async reconstructImage(text: string): Promise<Array<IReconstructImage>> {
    const res = this.utils.observableToPromise(
      this.rest.reconstructImage('POST', { prompt: text.trim() })
    );
    this.afterAction();
    return res;
  }

  public getJobById(
    id: number
  ): Promise<ApolloQueryResult<IReconstructionJobQuery>> {
    return this.utils.observableToPromise(this.gql.reconstructionJob(id));
  }

  public getSimilarProducts(imageURL: string): Promise<Array<ISimilarItem>> {
    return new Promise(async (resolve: any, reject: any) => {
      imageURL = this.utils.setUrlParam(imageURL, 'w', null);
      imageURL = this.utils.setUrlParam(imageURL, 'h', null);
      if (this._similarDictionary[imageURL]) {
        resolve(this._similarDictionary[imageURL]);
        return;
      }
      this._fetchingSimilar = true;
      const o = new CustomRequestOptions();
      o.showLoading = false;
      this._similarDictionary[imageURL] = await this.utils.observableToPromise(
        this.rest.similar(
          'POST',
          {
            image_url: imageURL,
          },
          '',
          o
        )
      );
      this._fetchingSimilar = false;
      resolve(this._similarDictionary[imageURL]);
      const classification = await this.getImageClassification(imageURL, false);
      this.pixels.sendPixel({
        event: 'similar',
        free_similar_count: this._similarDictionary[imageURL].length,
        premium_similar_count: this._similarDictionary[imageURL].length,
        classification,
        highest_score: this._similarDictionary[imageURL][0]?.score
      });
    });
  }

  public getImageClassification(
    imageURL: string,
    showLoading: boolean
  ): Promise<string> {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const o = new CustomRequestOptions();
        o.showLoading = showLoading;
        const res = await this.utils.observableToPromise(
          this.rest.classify(
            'POST',
            {
              image_url: imageURL,
            },
            '',
            o
          )
        );
        resolve(res.classification || res.top_category);
      } catch (e) {
        // reject(e);
        console.error(e);
        resolve('');
      }
    });
  }

  private subscribe() {
    if (this._isSubscribed) return;
    this._isSubscribed = true;
    this._subs.push(
      this.broadcaster
        .on('onDocumentFocus')
        .subscribe(this.onDocumentFocus.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe(async (d: any) => {
        const data = d as IPlaygroundNotification;
        if (data.status === RECONSTRUCT_JOB_STATUS.Failed)
          this.auth.refreshUserDate();
        if (
          data.notifications_types?.find(
            (t: IPlaygroundNotificationType) =>
              t.id === PlaygroundNotificationType.JOB_STATUS_CHANGE ||
              t.id ===
                PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED
          )
        ) {
          this.onCreationPush.next(data);
          if (data.job_id) {
            let c;
            if (
              data.status === RECONSTRUCT_JOB_STATUS.Queued ||
              data.job_id === this.creation?.id
            )
              c = this.utils.deepCopyByValue(
                (await this.getJobById(data.job_id)).data.reconstruction_jobs
              );
            if (data.status === RECONSTRUCT_JOB_STATUS.Queued) {
              this.broadcaster.broadcast('onLatestUpdated', c);
            }
            if (data.job_id === this.creation?.id) {
              Object.assign(this.creation, c);
              this.counter++;
            }
          }
        }
      })
    );
  }

  async onDocumentFocus() {
    if (this.creation) {
      const creation = (await this.getJobById(this.creation.id)).data
        .reconstruction_jobs;
      Object.assign(this.creation, creation);
      this.counter++;
    }
  }

  async updateJob(job: IReconstructJobUI): Promise<IReconstructJobUI> {
    const j = this.utils.deepCopyByValue(
      (await this.getJobById(job.id)).data.reconstruction_jobs
    ) as IReconstructJobUI;
    j._isCurrent = job._isCurrent;
    j._delayEnter = job._delayEnter;
    Object.assign(job, j);
    return job;
  }

  async generateImagesFromText() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_image_from_text',
    });
    const res = await this.reconstructImage(this.text);
    if (!res.length) {
      this.utils.notifyUser({
        type: ScreenNotificationType.Error,
        text: (res as any).error || 'unable to generate images',
      });
    }
    this.creation = null;
    this.createdImagesIndex = 0;
    this.createdImages = res.map((i) => i.url);
    this.similarItems = await this.getSimilarProducts(
      this.createdImages[this.createdImagesIndex]
    );
    this.afterAction();
  }

  async getAction(type: RECONSTRUCTION_ACTION): Promise<IReconstructionAction> {
    return this.enums.getActionByType(
      await this.auth.getReconstructionActions(),
      type
    );
  }

  async generateTexture(
    input: IReconstructTexture
  ): Promise<IReconstructJob> {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_texture',
      sub_click_type: 'texture',
    });
    if (input.text) input.text = input.text.trim();
    const payload = {
      action_id: RECONSTRUCTION_ACTION.RE_TEXTURE,
      text: input.text,
      images: input.images,
      source_glb_url: input.modelURL,
    } as IReconstruct;
    this.creation = (await this.utils.observableToPromise(
      this.rest.reconstruct('POST', payload)
    )) as IReconstructJobUI;
    // this.utils.observableToPromise(
    //   this.rest.reconstructTexture('POST', { prompt: text, glb: fileURL })
    // );
    this.afterAction();
    this.broadcaster.broadcast('generateTextureSent', this.creation);
    this.broadcaster.broadcast('onGenerating', this.creation);
    return this.creation;
  }

  afterAction() {
    this.auth.refreshUserDate();
  }

  async deleteJob(job: IReconstructJobUI) {
    if (this.creation?.id === job.id) this.creation = null;
    await this.utils.observableToPromise(
      this.rest.reconstructJobs('DELETE', null, `/${job.id}`)
    );
  }

  hasPreview() {
    return !!(
      this.creation ||
      (this.createdImages && this.createdImages[this.createdImagesIndex])
    );
  }

  // private unsubscribe() {
  //   if (!this._isSubscribed) return;
  //   this._isSubscribed = false;
  //   this._subs.forEach((s) => s.unsubscribe());
  //   this._subs = [];
  // }
}
