import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SeoService } from '../shared/seo/seo.service';
import { MetaOptions } from '../shared/seo/seo';
import { PlatformName } from '../shared/constants';
import { PixelsService } from '../shared/pixels.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { BallsComponent } from '../shared/balls/balls.component';
import { HexaLogoComponent } from '../shared/hexa-logo/hexa-logo.component';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-home',
    imports: [MatButtonModule, BallsComponent, HexaLogoComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  public platformName = 'HexaGen';
  constructor(
    private auth: AuthService,
    private router: Router,
    private seo: SeoService,
    private pixels: PixelsService
  ) { }

  ngOnInit() {
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | About Page`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI`;
    mOptions.description = `${mOptions.description} On this page you can read about the platform.`;
    mOptions.canonical = `${environment.domain}about`;
    this.seo.setMetaDate(mOptions);
  }

  async start() {
    if (await this.auth.isloggedIn()) {
      this.router.navigate(['/generate']);
    }
    else
      this.router.navigate(['/login']);
  }
}
