import { Component } from '@angular/core';

@Component({
    selector: 'app-blank',
    imports: [],
    templateUrl: './blank.component.html',
    styleUrl: './blank.component.scss'
})
export class BlankComponent {

}
