<div
  class="item"
  [ngClass]="{
    'animated-border not-ready': item.status === RECONSTRUCT_JOB_STATUS.Queued,
    'in-progress': item.status === InProgress,
    'failed': item.status === RECONSTRUCT_JOB_STATUS.Failed,
    current: item._isCurrent,
    'after-in': init,
    imm: loaded,
    thumbs: type === CREATIONS_LIST_TYPE.THUMBS || type === CREATIONS_LIST_TYPE.HORIZON_THUMBS,
    'thumbs-mobile': type === CREATIONS_LIST_TYPE.DESKTOP_INFO_HORIZON_THUMBS_MOBILE,
    'fade-out': fadeOut
  }"
  [id]="'job_' + item.id"
  [ngStyle]="{ 'transition-delay': (item._delayEnter || 0) * getMul() + 'ms' }"
  (click)="select(item)"
>
  <div class="item-inner animated-border-inner">
    <div class="img-wrap pos-rel">
      @if (item.status === InProgress) {
      <div class="percentage-wrap disp-flex col pos-abs justify-content-center">
        <div class="ttl-xs">{{ percentage }}%</div>
        <div class="lbl">Generating...</div>
      </div>
      } @if (item.status === RECONSTRUCT_JOB_STATUS.Failed) {
      <img
        src="/assets/images/failed.svg"
        alt="failed"
        class="badge-icon failed"
      />
      } @else if (item.action_id === RECONSTRUCTION_ACTION.REFINE) {
      <img
        src="/assets/images/refine_b.svg"
        alt="refine"
        class="badge-icon refined"
      />
      }
      <!-- @else if (item.action_id === RECONSTRUCTION_ACTION.PURCHASE_SIMILAR) {
      <img src="/assets/images/rock.svg" alt="refine" class="badge" />
      } -->
      <img
        class="flex-def preview"
        [ngClass]="{ 'no-preview': noPreview }"
        [src]="getPreview(item)"
        alt="preview"
        loading="lazy"
      />
      @if (item.reconstruction_jobs_inputs?.length > 1) {
        @if (item.reconstruction_jobs_inputs.length <= 9) {
          <mat-icon class="multiple-icon">filter_{{item.reconstruction_jobs_inputs.length}}</mat-icon>
        }
        @else {
          <mat-icon class="multiple-icon">filter_{{item.reconstruction_jobs_inputs.length}}_plus</mat-icon>
        }
      }
    </div>
    <div class="desc-wrap pos-rel">
      <div class="actions pos-abs">
        @if (actions) {
        <div class="buttons ghost btn-imgs disp-flex">
          <div class="button-wrap">
            @if (item.status === RECONSTRUCT_JOB_STATUS.Failed) {
            <button
              type="button"
              mat-raised-button
              class="btn btn-ghost btn-img"
              (click)="retry()"
              matTooltip="retry"
            >
              <img src="/assets/images/retry.svg" alt="text" />
            </button>
            }
          </div>
          <div class="button-wrap">
            <button
              type="button"
              mat-raised-button
              class="btn btn-ghost btn-img"
              (click)="duplicate()"
              matTooltip="copy prompt"
            >
              <img src="/assets/images/duplicate.svg" alt="text" />
            </button>
          </div>
          <div class="button-wrap">
            <button
              type="button"
              mat-raised-button
              class="btn btn-ghost btn-img"
              (click)="deleteMe()"
              matTooltip="delete"
            >
              <img src="/assets/images/delete.svg" alt="text" />
            </button>
          </div>
        </div>
        }
      </div>
      <div class="disp-flex col">
        <div class="flex-def">
          <div class="ellipsis txt-xxs stats">
            {{
              item.status === RECONSTRUCT_JOB_STATUS.Completed
                ? sourceAction?.description
                : item.status
            }}
            |
            {{ item.created_at | moment : "fromNow2" : tick.toString() }}
          </div>
        </div>
        <div class="flex-def">
          <div class="ellipsis txt-sm bold">"{{ name }}"</div>
        </div>
        @if (item.status === RECONSTRUCT_JOB_STATUS.Failed) {
        <app-badge
          [color]="'red'"
          [text]="'failed'"
          [img]="'/assets/images/failed.svg'"
        ></app-badge>
        } @else if (item.action_id === RECONSTRUCTION_ACTION.REFINE) {
        <app-badge
          [color]="'blue'"
          [text]="'refined'"
          [img]="'/assets/images/refine_b.svg'"
        ></app-badge>
        } @else if (item.action_id === RECONSTRUCTION_ACTION.PURCHASE_OBJAVERSE)
        {
        <app-badge
          [color]="'green'"
          [text]="'marketplace'"
          [img]="'/assets/images/marketplace.svg'"
        ></app-badge>
        } @else if (item.action_id === RECONSTRUCTION_ACTION.PURCHASE_SIMILAR) {
        <app-badge
          [color]="'neutral'"
          [text]="'premium'"
          [img]="'/assets/images/premium.svg'"
        ></app-badge>
        }
      </div>
    </div>
  </div>
</div>
