import { Component, OnDestroy } from '@angular/core';
import { PromptComponent } from '../prompt/prompt.component';
import { GenerateResultsComponent } from '../generate-results/generate-results.component';
import { SimilarComponent } from '../similar/similar.component';
import { CreationsComponent } from '../creations/creations.component';
import { IReconstructJobUI, ISimilarItem } from '../generate';
import { GenerateService } from '../generate.service';
import { ViewCreationComponent } from '../view-creation/view-creation.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CreationCardComponent } from '../creation-card/creation-card.component';
import { MatDialog } from '@angular/material/dialog';
import { SimilarPreviewComponent } from '../similar-preview/similar-preview.component';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { IPlaygroundNotification } from '../../shared/enums';
import { MatDividerModule } from '@angular/material/divider';
import { noPaddDialog } from '../../shared/constants';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-generate',
    imports: [
        PromptComponent,
        GenerateResultsComponent,
        SimilarComponent,
        CreationsComponent,
        ViewCreationComponent,
        RouterLink,
        CommonModule,
        CreationCardComponent,
        MatDividerModule,
        MatButtonModule,
    ],
    templateUrl: './generate.component.html',
    styleUrl: './generate.component.scss'
})
export class GenerateComponent implements OnDestroy {
  public showSimilar: boolean;
  private _subs: Array<Subscription>;
  constructor(
    public generateService: GenerateService,
    private dialog: MatDialog,
    private broadcaster: BroadcasterService
  ) {
    this._subs = [];
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    this._subs.push(
      this.broadcaster.on('onLatestUpdated').subscribe((data: any) => {
        if (
          // !generateService.creation &&
          !generateService.createdImages
        ) {
          debugger;
          generateService.creation = data;
        }
      })
    );
  }

  onAnnouncement(data: IPlaygroundNotification) {
    // if (
    //   this.generateService.latestUpdated &&
    //   data.job_id === this.generateService.latestUpdated.id
    // )
    //   this.generateService.counter++;
    this.generateService.counter++;
  }

  onSelect(item: IReconstructJobUI) {
    this.generateService.creation = item;
    this.generateService.counter++;
  }

  setSimilar(similar: ISimilarItem) {
    document.body.classList.add(noPaddDialog);
    this.dialog.open(SimilarPreviewComponent, {
      data: similar,
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove(noPaddDialog);
    });
  }

  async setPreview(imageURL: string) {
    this.generateService.similarItems =
      await this.generateService.getSimilarProducts(imageURL);
  }

  // close() {
  //   this.generateService.creation = null;
  //   this.generateService.createdImages = null;
  // }

  toggleSimilar() {
    this.showSimilar = !this.showSimilar;
  }

  ngOnDestroy() {
    this.generateService.counter = 0;
    this.generateService.creation = null;
    this._subs.forEach((f) => f.unsubscribe());
  }
}
