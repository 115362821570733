import { Component } from '@angular/core';
import { PricingViewComponent } from '../pricing-view/pricing-view.component';
import { PricingPeriod } from '../pricing';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SeoService } from '../../shared/seo/seo.service';
import { MetaOptions } from '../../shared/seo/seo';
import { PlatformName } from '../../shared/constants';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-pricing',
    imports: [PricingViewComponent, CommonModule, MatButtonModule],
    templateUrl: './pricing.component.html',
    styleUrl: './pricing.component.scss'
})
export class PricingComponent {
  public MONTHLY = PricingPeriod.MONTHLY;
  public YEARLY = PricingPeriod.YEARLY;
  public state = PricingPeriod.MONTHLY;
  constructor(
    private seo: SeoService
  ) {
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | Pricing`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI 3D models pricing`;
    mOptions.description = `${mOptions.description} On this page you can explore the platform's pricing.`;
    mOptions.canonical = `${environment.domain}pricing`;
    this.seo.setMetaDate(mOptions);
  }
}
