import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { IAuthType } from '../../shared/enums';
import { User, UserQueryData } from '../user';
import { ApolloQueryResult } from '@apollo/client';

@Component({
    selector: 'app-social',
    imports: [],
    templateUrl: './social.component.html',
    styleUrl: './social.component.scss'
})
export class SocialComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.startSocialSignup();
  }

  startSocialSignup() {
    // const signType = this.auth.ssoState.sign_on_type || null;
    this.saveUserProfile();
    this.auth.authType.next(IAuthType.SOCIAL);
    this.router.navigate(['/login']);
    // switch (signType) {
    //   case SignOnType.SIGN_IN:
    //     this.router.navigate(['/login']);
    //     break;
    //   case SignOnType.SIGN_UP:
    //     this.auth.authType.next(IAuthType.SOCIAL);
    //     this.router.navigate(['/onboarding']);
    //     break;
    //   default:
    //     break;
    // }
  }

  private saveUserProfile() {
    if (this.activatedRoute.snapshot.data['profile']) {
      const profile: User = (
        this.activatedRoute.snapshot.data[
          'profile'
        ] as ApolloQueryResult<UserQueryData>
      ).data.users;
      this.auth.atachRolesToUser(profile);
      this.auth.storeUser(profile);
    }
  }
}
