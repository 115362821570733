import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-sso-options',
    imports: [MatButtonModule],
    templateUrl: './sso-options.component.html',
    styleUrl: './sso-options.component.scss'
})
export class SsoOptionsComponent {
  constructor() {}

  ngOnInit(): void {}

  // public getFacebookLoginLink(): string {
  //   return `https://www.facebook.com/v11.0/dialog/oauth?client_id=${
  //     environment.facebookData.client_id
  //   }&redirect_uri=${encodeURI(
  //     environment.facebookData.redirect_uri
  //   )}&scope=openid,email&response_type=code&response_mode=query&nonce=0q74jnw5af1e&state=${encodeURI(
  //     environment.facebookData.state
  //   )}`;
  // }

  public getGoogleLoginLink(): string {
    return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
      environment.googleData.client_id
    }&response_type=code&state=${encodeURIComponent(
      environment.googleData.state
    )}&scope=openid email profile&redirect_uri=${encodeURIComponent(
      environment.googleData.redirect_uri
    )}&prompt=consent&include_granted_scopes=true&access_type=offline`;
  }
}
