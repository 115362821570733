import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  Input,
} from '@angular/core';
import { ViewerService } from '../../shared/viewer.service';
import { environment } from '../../../environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ExportFileType, IExpotedModel } from 'hexa-viewer-communicator';
import { GenerateService } from '../generate.service';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { UtilsService } from '../../shared/utils.service';
import { CommonModule } from '@angular/common';
import { IReconstructionAction, IReconstructTexture, RECONSTRUCTION_ACTION } from '../generate';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { themeColor } from '../../shared/constants';
import { EnumsService } from '../../shared/enums.service';
import { THREE_LATEST_VERSION } from 'asset-adjustments';
import { MatProgressBar } from '@angular/material/progress-bar';
import { OrSeparatorComponent } from '../../shared/or-separator/or-separator.component';

@Component({
  selector: 'app-ai-texture',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    CommonModule,
    CostEstComponent,
    OrSeparatorComponent,
    MatProgressBar
  ],
  templateUrl: './ai-texture.component.html',
  styleUrl: './ai-texture.component.scss'
})
export class AiTextureComponent implements AfterViewInit, OnDestroy {
  public viewerServer: string;
  public text: string;
  private _examplePromptIndex: number;
  private exporderModel: IExpotedModel;
  public viewerService: ViewerService;
  public action: IReconstructionAction;
  public loadingImageFiles: boolean;
  public imageToLoad: string;
  public themeColor = themeColor;
  THREE_LATEST_VERSION = THREE_LATEST_VERSION
  @ViewChild('hexaViewer') hexaViewer: ElementRef;
  @Input('glb-url') glbURL: string;
  constructor(
    private generateService: GenerateService,
    private resumableUploadService: ResumableUploadService,
    private utils: UtilsService,
    private enums: EnumsService
  ) {
    this._examplePromptIndex = 0;
    this.viewerService = new ViewerService(this.utils);
    this.viewerServer = environment.viewerServer;
  }

  ngAfterViewInit() {
    this.init();
  }

  async init() {
    this.action = (
      await this.generateService.getAction(RECONSTRUCTION_ACTION.RE_TEXTURE)
    );
    await this.viewerService.injectScript();
    this.hexaViewer.nativeElement.setAttribute('server', this.viewerServer);
    this.hexaViewer.nativeElement.setAttribute('tv', THREE_LATEST_VERSION);
    this.hexaViewer.nativeElement.setAttribute('theme-color', this.themeColor);
    this.viewerService.defineVC(this.hexaViewer.nativeElement);
    await this.viewerService.vc.onModelLoaded();
    this.handleModel();
  }

  isDisabled() {
    return (!(this.text || this.imageToLoad) || !this.exporderModel) && (!(this.text || this.imageToLoad) || !this.glbURL);
  }

  async handleModel() {
    this.exporderModel = await this.viewerService.vc.expotModel({
      compressPNG: false,
      downloadFile: false,
      type: ExportFileType.GLB,
    });
    // For the next time that the user might drop a file:
    this.exporderModel = null;
    await this.viewerService.vc.onModelLoaded();
    this.handleModel();
  }

  async texture() {
    let glbUrl = this.glbURL;
    if (!glbUrl) {
      const blob = new Blob([this.exporderModel.buffer], {
        type: 'application/octet-stream',
      }) as any;
      blob.lastModifiedDate = new Date();
      blob.name = 'scene.glb';
      glbUrl = await this.resumableUploadService.file(blob);
    }
    const payload = { modelURL: glbUrl } as IReconstructTexture;
    if (this.imageToLoad)
      payload.images = [this.imageToLoad];
    else
      payload.text = this.generateService.text;
    this.generateService.generateTexture(payload);
  }

  examplePrompt() {
    const prompts = this.enums.getTextureExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.text = p;
  }
  async onImageFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingImageFiles = true;
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png')
        continue;
      this.imageToLoad = await this.resumableUploadService.file(file);
      break;
    }
    event.srcElement.value = '';
    this.loadingImageFiles = false;
  }

  ngOnDestroy() {
    this.viewerService.destroy();
  }
}
