import { Injectable } from '@angular/core';
import { UtilsService } from '../shared/utils.service';
import { MessageRequest, MessagesHandlerService } from 'messages-handler';
import {
  Credentials,
  ISsoState,
  IReconstructionUsersSubscription,
  User,
  UserQueryData,
  UserRole,
} from './user';
import { RestService } from '../communication/rest.service';
import { ScreenNotificationType, IAuthType, KeyValuePair } from '../shared/enums';
import { BroadcasterService } from 'ng-broadcaster';
import { StorageService } from 'ng-storage-service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { EnumsService } from '../shared/enums.service';
import { GraphqlService } from '../communication/graphql.service';
import { environment } from '../../environments/environment';
import { ApolloQueryResult } from '@apollo/client';
import {
  IAllReconstructionAction,
  IReconstructionAction,
} from '../generate/generate';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static _AUTH_SRC = environment.authSrc;
  static USE_HEXA_SSO = false;
  public notificationsWebsocketEndpoint: string;
  private _user: User;
  private _authIframeElement: HTMLIFrameElement;
  private _getUserFromIframeResolves: Array<Function>;
  private _retailerIndex: number;
  private _isFetching: boolean;
  private _fetch: Observable<any>;
  private _ssoState: ISsoState;
  private _credits: number;
  private _subscription: IReconstructionUsersSubscription;
  public authType: BehaviorSubject<number>;
  private _reconstructionActions: Array<IReconstructionAction>;
  private _reconstructionActionsStack: Array<Function>;
  private _fetchNotificationTypesStack: Array<Function>;
  constructor(
    private utils: UtilsService,
    private mhService: MessagesHandlerService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private storage: StorageService,
    private router: Router,
    private enums: EnumsService,
    private gql: GraphqlService
  ) {
    this._reconstructionActionsStack = [];
    this._fetchNotificationTypesStack = [];
    if (!AuthService.USE_HEXA_SSO) this.user = this.storage.get('user') as User;
    this.notificationsWebsocketEndpoint =
      this.rest.notificationsWebsocketEndpoint;
    this._getUserFromIframeResolves = [];
    let obj = {
      actions: ['getHexaUserResponse', 'setHexaUserResponse'],
      invoke: 'onMessage',
      scope: this,
      options: {
        apply: true,
      },
    } as MessageRequest;
    this.mhService.onPostMessageEvent(
      obj,
      this.mhService.getReferrerByUrl(this.AUTH_SRC)
    );
    this.broadcaster.on('execLogout').subscribe(this.logout.bind(this));
    this.authType = new BehaviorSubject(IAuthType.INITIAL);
  }

  get user() {
    return this._user;
  }

  private set user(user: User) {
    this._user = user;
    if (this._user) {
      if (this._user.reconstruction_users_credits?.length)
        this._credits = this._user.reconstruction_users_credits[0].credits;
      if (this._user.reconstruction_users_subscriptions?.length)
        this._subscription = this._user.reconstruction_users_subscriptions.find(
          (s) => s.status === 'active'
        );
    }
  }

  get ssoState() {
    return this._ssoState;
  }

  set ssoState(value: ISsoState) {
    this._ssoState = value;
  }

  get credits() {
    return this._credits || 0;
  }

  get subscription() {
    return this._subscription;
  }

  private get AUTH_SRC() {
    // return `${AuthService._AUTH_SRC}&referrer=${location.hostname}`;
    return `${AuthService._AUTH_SRC}`;
  }

  private onMessage(obj: any) {
    const thisAny = this as any;
    if (typeof thisAny[obj.action] === 'function') thisAny[obj.action](obj.obj);
  }

  private onHexaUserResponse(user: User) {
    this.user = this.utils.deepCopyByValue(user);
    this._getUserFromIframeResolves.forEach((f) => f(this.user));
  }

  private getUserFromIframe(): Promise<User> {
    return new Promise(async (resolve, reject) => {
      if (AuthService.USE_HEXA_SSO) {
        if (!this._authIframeElement)
          this._authIframeElement = await this.utils.injectIframe(
            this.AUTH_SRC
          );
        this._getUserFromIframeResolves.push(resolve);
        this.mhService.postToChild(
          { action: 'getHexaUser' },
          this._authIframeElement
        );
      }
    });
  }

  private setUserFromIframe(user: User) {
    return new Promise(async (resolve, reject) => {
      if (AuthService.USE_HEXA_SSO) {
        if (!this._authIframeElement)
          this._authIframeElement = await this.utils.injectIframe(
            this.AUTH_SRC
          );
        this.mhService.postToChild(
          { action: 'setHexaUser', params: [user] },
          this._authIframeElement
        );
      }
    });
  }

  async isloggedIn(): Promise<boolean> {
    if (!this._user && AuthService.USE_HEXA_SSO) await this.getUserFromIframe();
    return !!this._user;
  }

  setRetailerIndex(index: number) {
    this._retailerIndex = index;
    this.storage.set('retailerIndex', this._retailerIndex);
    this.broadcaster.broadcast('onRetailerIndex', this._retailerIndex);
  }

  getRetailerIndexById(retailerId: number): number | null {
    for (let i = 0; i < this._user.retailers_users.length; i++) {
      if (this._user.retailers_users[i].retailer_id == retailerId) {
        return i;
      }
    }
    return null;
  }

  getRetailerIdByIndex(index: number): number | null {
    if (this._user.retailers_users[index])
      return this._user.retailers_users[index].retailer_id;
    return null;
  }

  setRetailerIndexById(retailerId: number): boolean {
    if (!this._user) return false;
    for (let i = 0; i < this._user.retailers_users.length; i++) {
      if (this._user.retailers_users[i].retailer_id == retailerId) {
        if (this._retailerIndex != i) this.setRetailerIndex(i);
        return true;
      }
    }
    return false;
  }

  atachRolesToUser(user: User) {
    if (!user.roles && user.users_roles) {
      user = this.utils.deepCopyByValue(user);
      user.roles = [];
      for (let i = 0; i < user.users_roles.length; i++) {
        if (user.users_roles[i].roles) {
          for (let j = 0; j < user.users_roles[i].roles.length; j++) {
            user.roles.push(user.users_roles[i].roles[j]);
          }
        }
      }
    }
    return user;
  }

  login(
    method: string,
    credentials: Credentials,
    query?: string
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      this.rest.auth(method, credentials, query).subscribe(
        (result: User) => {
          this.user = this.utils.deepCopyByValue(result);
          resolve(result);
          this.setUserFromIframe(this.user);
        },
        (err: any) => {
          if (err && (err._body || err.error)) {
            this.utils.notifyUser({
              text:
                err.error === 'Illegal user/password.'
                  ? 'Incorrect email address or password.'
                  : err._body || err.error,
              type: ScreenNotificationType.Error,
              action: 'OK',
            });
            reject(err._body || err.error);
          } else {
            this.utils.notifyUser({
              text: 'login attempt failure',
              type: ScreenNotificationType.Error,
              action: 'OK',
            });
          }
          reject();
        }
      );
    });
  }

  onLoginSuccess(
    user: User,
    navToDefault = true,
    broadcastOnLogin = true,
    remember = true
  ) {
    this.user = this.utils.deepCopyByValue(user);
    if (remember) {
      this.setUserFromIframe(this._user);
      this.storage.set('user', this.user);
      this.storage.set('token', this.user.token);
    }
    if (broadcastOnLogin) this.broadcaster.broadcast('onLogin', this._user);
    if (navToDefault) this.router.navigate(['/generate']);
    delete this._ssoState;
  }

  onLogout() {
    this.storage.remove('user');
    this.storage.remove('token');
    this._retailerIndex = 0;
    this.storage.remove('retailerIndex');
    delete this._user;
    this.setUserFromIframe(this._user);
    this.broadcaster.broadcast('onLogout', this._user);
    this.router.navigate(['/']);
    delete this._ssoState;
  }

  logout() {
    this.rest.auth('delete').subscribe(
      (result) => this.onLogout(),
      (err) => this.onLogout()
    );
  }

  userProfile(uid: number): Observable<User> {
    if (this._isFetching) return this._fetch;
    this._isFetching = true;
    this._fetch = this.gql.user(uid).pipe(
      map((res) => {
        let user = this.utils.deepCopyByValue(res.data.users);
        if (!user.users_roles) user.users_roles = [{}] as Array<UserRole>;
        this._isFetching = false;
        return user;
      })
    );
    return this._fetch;
  }

  public fetchUser(
    userId: number
  ): Observable<ApolloQueryResult<UserQueryData>> {
    return this.gql.user(userId);
  }

  refreshUserDate() {
    if (!this._user) return;
    this.userProfile(this._user.id).subscribe((user) => {
      this.atachRolesToUser(user);
      user.token = this._user.token;
      this.user = this.utils.deepCopyByValue(user);
      this.storage.set('user', this._user);
      if (!this._user.retailers_users[this._retailerIndex])
        this.setRetailerIndex(0);
      this.broadcaster.broadcast('userRefreshed', this._user);
    });
  }

  fetchNotificationTypes() {
    return new Promise(async (resolve, reject) => {
      let data = this.enums.getNotificationTypes();
      if (data)
        resolve(data);
      this._fetchNotificationTypesStack.push(resolve);
      if (this._fetchNotificationTypesStack.length > 1)
        return;
      data = await this.utils.observableToPromise(this.rest.notificationTypes()) as Array<KeyValuePair>;
      this.enums.setNotificationTypes(data);
      this.broadcaster.broadcast('notificationRefreshed', data);
      resolve(data);
      this._fetchNotificationTypesStack.forEach(f => f(data));
    });
  }

  public storeUser(user: User): void {
    this.user = this.utils.deepCopyByValue(user);
    this.addInvitingArtist();
    this.storage.set('user', user);
  }

  private addInvitingArtist(): void {
    // for referrer:
    // const invitingUser = this.storage.get('invitingUser');
    // if (invitingUser && !this.user.referrer_artist_id) {
    //     this.user.referrer_artist_id = invitingUser;
    // }
  }

  public storeToken(token: string): void {
    this.storage.set('token', token);
  }

  deleteUserForever(userId: number) {
    return this.utils.observableToPromise(
      // this.rest.userProfile('delete', null, '/' + userId)
      this.rest.userProfile('delete', null)
    );
  }

  public getReconstructionActions(): Promise<Array<IReconstructionAction>> {
    return new Promise(async (resolve, reject) => {
      if (this._reconstructionActions) {
        resolve(this._reconstructionActions);
        return;
      }
      this._reconstructionActionsStack.push(resolve);
      if (this._reconstructionActionsStack.length > 1)
        return;
      const res = (await this.utils.observableToPromise(
        this.gql.allReconstructionActions()
      )) as IAllReconstructionAction;
      this._reconstructionActions = res.data.allReconstructionActions;
      resolve(this._reconstructionActions);
      this._reconstructionActionsStack.forEach(f => f(this._reconstructionActions));
    });
  }
}
