import { Component } from '@angular/core';

@Component({
    selector: 'app-buy',
    imports: [],
    templateUrl: './buy.component.html',
    styleUrl: './buy.component.scss'
})
export class BuyComponent {

}
