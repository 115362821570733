import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IReconstructImageUI } from '../generate';
import { SrcSuffixPipe } from '../../shared/src-suffix.pipe';
import { CommonModule } from '@angular/common';
import { UtilsService } from '../../shared/utils.service';

@Component({
    selector: 'app-generated-image-card',
    imports: [SrcSuffixPipe, CommonModule],
    templateUrl: './generated-image-card.component.html',
    styleUrl: './generated-image-card.component.scss'
})
export class GeneratedImageCardComponent implements OnInit, AfterViewInit {
  @Input() item: IReconstructImageUI;
  public init: boolean;
  public loaded: boolean;
  constructor(private utils: UtilsService) {}
  ngOnInit() {
    if (typeof this.item._delayEnter !== 'number') {
      this.init = true;
      this.loaded = true;
    } else setTimeout(() => (this.loaded = true), this.item._delayEnter);
    // this._subs.push(
    //   this.broadcaster
    //     .on('onWebsocketOpen')
    //     .subscribe(this._onConnection.bind(this))
    // );
  }

  async ngAfterViewInit() {
    await this.utils.setTimeout();
    this.init = true;
  }
}
