import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-balls',
    imports: [CommonModule],
    templateUrl: './balls.component.html',
    styleUrl: './balls.component.scss'
})
export class BallsComponent {
  @Input('has-scroll') hasScroll: boolean;
}
