import { Component, Input } from '@angular/core';
import { PaddleService } from '../../payments/paddle.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { UtilsService } from '../../shared/utils.service';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { RestService } from '../../communication/rest.service';
import { AuthService } from '../auth.service';
import { ScreenNotificationType } from '../../shared/enums';
import { IUserProfile } from '../user';
import { Router, RouterLink } from '@angular/router';
import { UsageComponent } from '../usage/usage.component';
import { ProgressBarComponent } from '../../shared/progress-bar/progress-bar.component';
import { IReconstructionTransaction } from '../../pricing/pricing';
import { defaultMonthlyCredits } from '../../shared/constants';

@Component({
    selector: 'app-user-profile',
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatProgressBarModule,
        CommonModule,
        UsageComponent,
        ProgressBarComponent,
        RouterLink
    ],
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {
  public firstLetter: string;
  public uploading: boolean;
  public profile: IUserProfile;
  public defaultMonthlyCredits = defaultMonthlyCredits;
  @Input() transactions: Array<IReconstructionTransaction>;
  constructor(
    // public dialogRef: MatDialogRef<UserProfileComponent>,
    // @Inject(MAT_DIALOG_DATA) public profile: IUserProfile,
    private paddleService: PaddleService,
    private utils: UtilsService,
    private eesumableUploadService: ResumableUploadService,
    private rest: RestService,
    private auth: AuthService,
    private router: Router
  ) {
    this.init();
  }

  async init() {
    this.profile = await this.paddleService.getUserProfile();
    this.firstLetter = (
      this.profile.user.firstname || this.profile.user.email
    ).substring(0, 1);
  }

  // upgrage() {}

  async cancelSubscription() {
    await this.paddleService.cancelSubscription();
    this.utils.notifyUser({
      type: ScreenNotificationType.Success,
      text: 'Subscription successfully canceled',
    });
  }

  async deleteAccount() {
    if (!confirm('Are you sure you want do delete your account forever?')) return;
    await this.auth.deleteUserForever(this.auth.user.id);
    this.utils.notifyUser({
      type: ScreenNotificationType.Success,
      text: 'Account was deleted'
    });
    // this.close();
    this.auth.onLogout();
    this.router.navigate(['/']);
    // this.broadcaster.broadcast('onUsersChange');
  }

  // close() {
  //   this.dialogRef.close();
  // }

  async save() {
    const user = this.utils.deepCopyByValue(this.profile.user);
    delete user.users_roles;
    await this.utils.observableToPromise(
      this.rest.userProfile('PUT', user, `/${this.profile.user.id}`)
    );
    this.auth.refreshUserDate();
  }

  async selectFile() {
    const files = await this.utils.getFiles({
      accept: 'image/*',
      multiple: false,
    });
    if (files[0]) {
      this.uploading = true;
      this.profile.user.avatar_url = await this.eesumableUploadService.file(
        files[0]
      );
      this.profile.user.avatar_url = this.utils.getSrcSuffix(
        this.profile.user.avatar_url,
        '?w=60&h=60',
        true
      );
      this.uploading = false;
    }
  }
}
