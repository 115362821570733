import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CREATIONS_LIST_TYPE,
  IAllReconstructionJobs,
  IReconstructJob,
  IReconstructJobOptions,
  IReconstructJobUI,
  RECONSTRUCTION_ACTION,
  RECONSTRUCT_JOB_STATUS,
} from '../generate';
import { CommonModule } from '@angular/common';
import { UtilsService } from '../../shared/utils.service';
import { CreationsService } from '../creations.service';
import { ScrollHelper } from '../../shared/scroll-helper';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import {
  IPlaygroundNotification,
  PlaygroundNotificationType,
} from '../../shared/enums';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { GraphqlService } from '../../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client';
import { CreationCardComponent } from '../creation-card/creation-card.component';
import { GenerateService } from '../generate.service';
import { MetaOptions } from '../../shared/seo/seo';
import { PlatformName, mobileThreshold } from '../../shared/constants';
import { environment } from '../../../environments/environment';
import { SeoService } from '../../shared/seo/seo.service';
import { BodyService } from '../../shared/body.service';

@Component({
    selector: 'app-creations',
    imports: [
        CommonModule,
        MatButtonModule,
        RouterLink,
        CreationCardComponent,
    ],
    templateUrl: './creations.component.html',
    styleUrl: './creations.component.scss'
})
export class CreationsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() current: IReconstructJob;
  @Input('type') type: CREATIONS_LIST_TYPE;
  @Input() actions: boolean;
  @Input() counter: number;
  @ViewChild('scroll') public scroll: ElementRef;
  @Output() onSelect: EventEmitter<IReconstructJobUI>;
  private scrollHelper: ScrollHelper;
  private _subs: Array<Subscription>;
  public CREATIONS_LIST_TYPE = CREATIONS_LIST_TYPE;
  constructor(
    private utils: UtilsService,
    public creationsService: CreationsService,
    private broadcaster: BroadcasterService,
    private gql: GraphqlService,
    private generateService: GenerateService,
    private seo: SeoService,
    private body: BodyService
  ) {
    this.onSelect = new EventEmitter<IReconstructJobUI>();
    this._subs = [];
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | AI Creations`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI 3D models`;
    mOptions.description = `${mOptions.description} On this page you can visit your AI 3D models.`;
    mOptions.canonical = `${environment.domain}creations`;
    this.seo.setMetaDate(mOptions);
  }

  async ngOnInit() {
    CreationsService.DEFAULT_FILTER.limit =
      this.type === CREATIONS_LIST_TYPE.INFO ? 20 : 60;
    const items = (await this.utils.observableToPromise(
      this.gql.allReconstructionJobs({
        limit: CreationsService.DEFAULT_FILTER.limit,
        offset: CreationsService.DEFAULT_FILTER.offset,
        is_archived: CreationsService.DEFAULT_FILTER.is_archived,
      } as IReconstructJobOptions)
    )) as ApolloQueryResult<IAllReconstructionJobs>;
    this.creationsService.items = this.utils.deepCopyByValue(
      items.data.allReconstructionJobs.rows
    );
    this.creationsService.itemsCount = items.data.allReconstructionJobs.count;
    for (let i = 0; i < this.creationsService.items.length; i++) {
      (this.creationsService.items[i] as IReconstructJobUI)._delayEnter =
        i * 50;
    }
    this.updateCurrent();
    this._subs.push(
      this.broadcaster.on('onPublish').subscribe((data: any) => {
        this.onPublish(data);
      })
    );
    this._subs.push(
      this.broadcaster.on('onGenerating').subscribe((data: any) => {
        this.onPublish(data);
      })
    );
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    this._subs.push(
      this.broadcaster.on('onJobUpdate').subscribe((data: any) => {
        const job = this.creationsService.items.find(
          (c) => c.id === data.id
        ) as IReconstructJob;
        job.preview = data.preview;
        this.onAnnouncement(data);
      })
    );
    this._subs.push(
      this.broadcaster
        .on('onWebsocketOpen')
        .subscribe(this._onConnection.bind(this))
    );
    this._subs.push(
      this.broadcaster
        .on('onLatestUpdated')
        .subscribe(this._onLatestUpdated.bind(this))
    );
    // this.cdr.detectChanges();
    await this.utils.setTimeout();
    this.initScrollHelper();
    this._subs.push(
      this.broadcaster
        .on('onDocumentFocus')
        .subscribe(this.onDocumentFocus.bind(this))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['current'] || changes['counter']) this.updateCurrent();
  }

  private _onLatestUpdated(data: any | IReconstructJobUI) {
    const job = this.creationsService.items.find((i) => i.id === data.id);
    if (job) {
      this.creationsService.copyCreation(data, job);
    }
  }

  private _onConnection() {
    this.updateItems();
  }

  // update all in progress or queued creations
  async updateItems() {
    for (let i = 0; i < this.creationsService.items.length; i++) {
      switch (this.creationsService.items[i].status) {
        case RECONSTRUCT_JOB_STATUS['In Progress']:
        case RECONSTRUCT_JOB_STATUS.Queued: {
          this.creationsService.items[i] = this.utils.deepCopyByValue(
            (
              await this.generateService.getJobById(
                this.creationsService.items[i].id
              )
            ).data.reconstruction_jobs
          );
          break;
        }
      }
    }
  }

  updateCurrent() {
    if (this.creationsService.items) {
      if (this.current) {
        this.creationsService.items.forEach((i: IReconstructJobUI) => {
          if (this.current.id === i.id) i._isCurrent = true;
          else i._isCurrent = false;
        });
      } else {
        this.creationsService.items.forEach(
          (i: IReconstructJobUI) => (i._isCurrent = false)
        );
      }
    }
  }

  async onPublish(data: IReconstructJobUI) {
    data._delayEnter = 1;
    this.addNewItem(data);
    setTimeout(() => {
      const j = document.getElementById(`job_${data.id}`);
      if (j) this.body.scrollToElement(new ElementRef(j));
    });
    // if (this.scroll?.nativeElement) {
    //   this.scroll.nativeElement.scrollTop = 0;
    //   this.scroll.nativeElement.scrollLeft = 0;
    // }
  }

  addNewItem(item: IReconstructJob) {
    this.creationsService.items.unshift(item);
    this.creationsService.itemsCount++;
    setTimeout(() => {
      if (!this.scroll?.nativeElement) this.initScrollHelper();
    });
  }

  async onAnnouncement(data: IPlaygroundNotification) {
    if (
      data.notifications_types?.find(
        (t) =>
          t.id === PlaygroundNotificationType.JOB_STATUS_CHANGE ||
          t.id === PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED
      )
    ) {
      if (this.creationsService.items) {
        let jobDB: IReconstructJobUI;
        if (data.job_id)
          jobDB = this.utils.deepCopyByValue(
            (await this.generateService.getJobById(data.job_id)).data
              .reconstruction_jobs
          );
        const job = this.creationsService.items.find(
          (c) => c.id === data.job_id
        ) as IReconstructJobUI;
        if (job) {
          if (jobDB) {
            this.creationsService.copyCreation(jobDB, job);
          } else {
            job.status = data.status;
            job.viewer_url = data.viewer_url;
          }
        } else {
          this.addNewItem(jobDB);
        }
        this.creationsService.counter++;
      }
    }
  }

  initScrollHelper() {
    if (!this.scroll?.nativeElement)
      this.scroll = new ElementRef(document.getElementById('creation-list'));
    if (this.scrollHelper) this.scrollHelper.destroy();
    if (this.scroll.nativeElement) {
      this.scrollHelper = new ScrollHelper(this.scroll.nativeElement);
      this.scrollHelper.hirizontalThreshold = mobileThreshold;
      this.scrollHelper.maxPercentage = 0.6;
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
    }
  }

  fetchMore() {
    if (typeof this.creationsService.itemsCount === 'number') {
      if (
        this.creationsService.itemsCount <= this.creationsService.items.length
      )
        return;
    } else return;
    this.creationsService.scrollOffset = this.creationsService.items.length;
    this.creationsService.searchByQuery();
  }

  async select(item: IReconstructJobUI) {
    // if (item.status === RECONSTRUCT_JOB_STATUS.Completed) {
    this.current = item;
    this.onSelect.next(item);
    // }
    const preview = this.utils.getPreview(item);
    if (preview)
      this.generateService.similarItems =
        await this.generateService.getSimilarProducts(preview);
  }

  retry(item: IReconstructJobUI) {
    // this.generateService.retry(item);
    this.duplicate(item);
  }

  duplicate(item: IReconstructJobUI) {
    switch (item.source_action_id || item.action_id) {
      case RECONSTRUCTION_ACTION.RE_TEXTURE: {
        this.utils.forceRedirectTo('/ai-texture', {
          duplicate: item.id,
        });
        break;
      }
      case RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT:
      case RECONSTRUCTION_ACTION.RECONSTRUCTION: {
        this.utils.forceRedirectTo('/generate', {
          duplicate: item.id,
        });
        break;
      }
    }
    // this.generateService.duplicate(item);
  }

  deleteMe(item: IReconstructJobUI) {
    this.creationsService.removeItem(item);
    this.generateService.deleteJob(item);
  }

  onDocumentFocus() {
    this.creationsService.items.forEach((item: IReconstructJobUI) => {
      if (
        item.status != RECONSTRUCT_JOB_STATUS.Completed &&
        item.status != RECONSTRUCT_JOB_STATUS.Failed
      ) {
        this.generateService.getJobById(item.id).then((j) => {
          const jobDB = j.data.reconstruction_jobs as IReconstructJobUI;
          this.creationsService.copyCreation(jobDB, item);
        });
      }
    });
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
    this.creationsService.counter = 0;
    this.generateService.creation = null;
  }
}
