import { Component, Input, OnInit } from '@angular/core';
import { ViewerService } from '../viewer.service';
import { MatDialog } from '@angular/material/dialog';
import { AiTuxtureDialogComponent } from '../../generate/ai-texture-dialog/ai-texture-dialog.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BroadcasterService } from 'ng-broadcaster';
import { Subscription } from 'rxjs';
import { IReconstructJobUI } from '../../generate/generate';
import { UtilsService } from '../utils.service';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { environment } from '../../../environments/environment';
import { TextureMimeType } from 'hexa-viewer-communicator';

@Component({
    selector: 'app-viewer-controls',
    imports: [CommonModule, MatButtonModule, MatTooltipModule],
    templateUrl: './viewer-controls.component.html',
    styleUrl: './viewer-controls.component.scss'
})
export class ViewerControlsComponent implements OnInit {
  @Input('viewerService') viewerService: ViewerService;
  @Input() viewOnly: boolean;
  private sub: Subscription;
  public isQA: boolean;
  constructor(
    private dialog: MatDialog,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    public roles: RolesHelperService
  ) {
    this.isQA = !environment.production;
  }

  ngOnInit() {
    this.viewerService.standard = true;
    this.utils.preloadImage(`https://v.hexa3d.io/third-party/textures/matcap-porcelain-white.jpg`);
    this.utils.preloadImage(`https://v.hexa3d.io/third-party/textures/uv.webp`);
  }

  download() {
    this.viewerService.onDownload.next(null);
  }

  refine() {
    this.viewerService.refine();
  }

  share() {
    this.viewerService.share();
  }

  afterSent(job: IReconstructJobUI) {
    this.clearSub();
    if (this.dialog)
      this.dialog.closeAll();
  }

  aiTexture() {
    this.clearSub();
    this.sub = this.broadcaster.on('generateTextureSent').subscribe((data: any) => {
      this.afterSent(data);
    });
    this.dialog.open(AiTuxtureDialogComponent, {
      data: {
        glbURL: this.viewerService.glbUrl
      },
    });
    this.dialog.afterAllClosed.subscribe(() => {
      this.clearSub();
    });
  }

  async renderBG() {
    const f = await this.utils.getFiles({
      multiple: false,
      accept: 'image/*'
    });
    if (f[0]) {
      const base = await this.utils.getBase64FromFile(f[0]);
      const md = await this.viewerService.vc.getMeshesData();
      Object.keys(md).forEach((name: string) => {
        this.viewerService.vc.applyTexture({
          mapType: 'envMap',
          materialName: md[name].materialName,
          mimeType: TextureMimeType.IMAGE,
          src: base,
          intensity: 2
        });
      });
      this.viewerService.style['background-image'] = `url('${base}')`;
      await this.viewerService.vc.setLightsByJson({});
      const ss = await this.viewerService.vc.getCurrentScreenshot();
      this.utils.multipleDownloads([await this.utils.merge2Images(base, ss)], 'render.png');
    }
  }

  clearSub() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }
}
