import { Component } from '@angular/core';

@Component({
    selector: 'app-hexa-logo',
    imports: [],
    templateUrl: './hexa-logo.component.html',
    styleUrl: './hexa-logo.component.scss'
})
export class HexaLogoComponent {

}
