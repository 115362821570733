import { Component } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { TermsViewComponent } from '../terms-view/terms-view.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-terms',
    imports: [MatDialogContent, TermsViewComponent, MatDialogActions, MatButtonModule, MatDialogClose],
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss'
})
export class TermsComponent {

}
