import { Component, Input } from '@angular/core';
import { SrcSuffixPipe } from '../../shared/src-suffix.pipe';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { IReconstruct, RECONSTRUCTION_ACTION } from '../generate';
import { GenerateService } from '../generate.service';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { CommonModule } from '@angular/common';
import { ImageCardComponent } from '../../shared/image-card/image-card.component';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { UtilsService } from '../../shared/utils.service';
import { ScreenNotificationType } from '../../shared/enums';

@Component({
    selector: 'app-generate-results',
    imports: [
        SrcSuffixPipe,
        MatIconButton,
        MatIconModule,
        MatButtonModule,
        MatProgressBar,
        CommonModule,
        ImageCardComponent,
        CostEstComponent,
    ],
    templateUrl: './generate-results.component.html',
    styleUrl: './generate-results.component.scss'
})
export class GenerateResultsComponent {
  @Input() images: Array<string>;
  public loading: boolean;
  public reconstructionCost: number;
  constructor(
    public generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.init();
  }

  async generate(src: string) {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_preview',
    });
    const payload = {
      action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
      text: this.generateService.text,
      images: [src]
    } as IReconstruct;
    const res = await this.generateService.imageTo3D(payload);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'generating . . .',
    });
    this.broadcaster.broadcast('onPublish', res);
  }

  retry() {
    this.generateService.generateImagesFromText();
  }

  async select(index: number) {
    this.generateService.createdImagesIndex = index;
    this.generateService.similarItems =
      await this.generateService.getSimilarProducts(
        this.generateService.createdImages[
          this.generateService.createdImagesIndex
        ]
      );
  }

  private async init() {
    this.reconstructionCost = (await this.generateService.getAction(RECONSTRUCTION_ACTION.RECONSTRUCTION)).credits;
  }
}
