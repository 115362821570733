import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { MatDividerModule } from '@angular/material/divider';
import { IUserProfile } from '../../auth/user';
import { PaddleService } from '../../payments/paddle.service';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { defaultMonthlyCredits } from '../constants';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationsService } from '../notifications.service';

@Component({
    selector: 'app-header-menu',
    imports: [
        RouterLink,
        MatDividerModule,
        DecimalPipe,
        ProgressBarComponent,
        MatButtonModule,
        CommonModule
    ],
    templateUrl: './header-menu.component.html',
    styleUrl: './header-menu.component.scss'
})
export class HeaderMenuComponent implements OnDestroy {
  public firstLetter: string;
  public profile: IUserProfile;
  public isloggedIn: boolean;
  public defaultMonthlyCredits = defaultMonthlyCredits;
  private _subs: Array<Subscription>;
  @Output('on-close') onClose: EventEmitter<unknown>;
  constructor(
    public auth: AuthService,
    public paddleService: PaddleService,
    private broadcaster: BroadcasterService,
    public notificationsService: NotificationsService
  ) {
    this._subs = [];
    this.onClose = new EventEmitter<unknown>();
    this.init();
    this._subs.push(
      this.broadcaster.on('onLogin').subscribe(this.init.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onLogout').subscribe(this.init.bind(this))
    );
  }

  public get showNotifications(): boolean {
    return this.notificationsService.getSidebarState();
  }

  public set showNotifications(value: boolean) {
    setTimeout(() => {
      this.notificationsService.toggleSidebar(value);
    });
  }

  async init() {
    this.isloggedIn = await this.auth.isloggedIn();
    if (this.isloggedIn) {
      this.profile = await this.paddleService.getUserProfile();
      this.firstLetter = (
        this.profile.user.firstname || this.profile.user.email
      ).substring(0, 1);
    }
  }

  logout() {
    this.auth.logout();
    this.close();
  }

  close() {
    this.onClose.next(null);
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
