import { Component, Input } from '@angular/core';
import { ITopupCardDetails } from '../pricing';
import { DecimalPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-topups-card',
    imports: [DecimalPipe, MatButtonModule],
    templateUrl: './topups-card.component.html',
    styleUrl: './topups-card.component.scss'
})
export class TopupsCardComponent {
  @Input() details: ITopupCardDetails;

  buy() {
    // if (this.details.priceId) {
    //   this.cliced = true;
    //   if (this.auth.subscription) {
    //     await this.paddleService.changeSubscription(this.details.priceId, 1);
    //     this.utils.notifyUser({
    //       type: ScreenNotificationType.Success,
    //       text: 'Subscription successfully updated',
    //     });
    //   } else {
    //     await this.paddleService.checkout(this.details.priceId, 1);
    //     this.utils.notifyUser({
    //       type: ScreenNotificationType.Success,
    //       text: 'Subscription successfully acquired',
    //     });
    //   }
    //   this.router.navigate(['/generate']);
    // } else {
    //   if (await this.auth.isloggedIn()) this.router.navigate(['/generate']);
    //   else this.router.navigate(['/login']);
    // }
  }
}
