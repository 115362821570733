<div class="wrap component-wrap col">
  <div class="buttons disp-flex">
    <div class="button-wrap">
      <button
        (click)="state = FOREIGN"
        [ngClass]="{ active: state === FOREIGN }"
        type="button"
        mat-raised-button
        class="btn btn-g large small-text"
      >
        Upload model
      </button>
    </div>
    <div class="button-wrap">
      <button
        (click)="state = EXISTING"
        [ngClass]="{ active: state === EXISTING }"
        type="button"
        mat-raised-button
        class="btn btn-g large sp small-text"
      >
        Select from library
      </button>
    </div>
  </div>
  @if (uploading) {
  <div class="pos-rel">
    <mat-progress-bar class="pos-abs" mode="indeterminate"></mat-progress-bar>
  </div>
  }
  <div class="prompt-inner disp-flex col" [ngClass]="{'existing': state === EXISTING}">
    @switch (state) { @case(EXISTING) {
    <div class="disp-flex space-bottom-sm">
      <h3 class="txt-xxs bold capitalize no-marg flex-1 hidden-mobile">
        select 3D model
      </h3>
      <!-- <a [routerLink]="['/creations']" class="txt-sm link">
        view all <span class="hidden-desktop">creations</span>
      </a> -->
    </div>
    <app-creations
      [counter]="generateService.counter"
      [type]="CREATIONS_LIST_TYPE.HORIZON_THUMBS"
      class="space-bottom-lg"
      [current]="generateService.creation"
      (onSelect)="onSelect($event)"
    ></app-creations>
    } @case(FOREIGN) {
    <!-- <div>
      <div class="txt-xxs bold capitalize-fl space-bottom-sm">
        creation name
      </div>
      <div>
        <mat-form-field class="w-100">
          <input
            placeholder="Insert creation name"
            matInput
            [(ngModel)]="generateService.text"
          />
        </mat-form-field>
      </div>
    </div> -->
    <div
      class="pos-rel viewer-wrap space-bottom-lg"
      [ngClass]="{ 'has-file': hasFile }"
    >
      <div class="space-bottom-sm upload-instructions">
        <span class="txt-xxs bold">Upload 3D model</span>
      </div>
      @if (!hasFile && loadingFiles) {
      <mat-progress-bar class="pos-abs" mode="indeterminate"></mat-progress-bar>
      }
      <input
        placeholder="drop model here"
        type="file"
        class="poiner"
        accept="*/*"
        miltiple="true"
        (change)="onFilesChange($event)"
      />
      <div class="dummy disp-flex pos-abs col text-center">
        <img src="/assets/images/cube.svg" alt="cube" />
        <div>
          <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
            upload model
          </div>
          <div class="desc2 txt-xxs">
            glb, glTF, fbx, obj, dae, stl and more
          </div>
        </div>
      </div>
      <hexa-viewer
        id="ai-texture-prompt-viewer"
        #hexaViewer
        class="w-100"
        embed-loader="true"
        autorotate="true"
        auto-adjust="true"
      ></hexa-viewer>
    </div>
    } }
    @if (hasFile || state === EXISTING) {
    <div>
      <div>
        <div class="disp-flex space-bottom-sm">
          <span class="txt-xxs bold capitalize-fl">texture prompt</span>
          <a
            href="javascript:void(0)"
            class="align-end-flex link txt-xxs bold"
            (click)="examplePrompt()"
            >example prompt</a
          >
        </div>
        <mat-form-field class="w-100 ta-wrap">
          <textarea
            rows="6"
            [(ngModel)]="generateService.text"
            placeholder="smooth, matte black finish with subtle carbon fiber detailing along the edges..."
            matInput
          ></textarea>
        </mat-form-field>
        <mat-form-field class="w-100 input-wrap">
          <input
            placeholder="Insert creation name"
            matInput
            [(ngModel)]="generateService.text"
          />
        </mat-form-field>
      </div>
      <div>
        <app-or-separator>or</app-or-separator>
      </div>
      <div>
        <div class="space-bottom-sm">
          <span class="txt-xxs bold">Upload reference image</span>
        </div>
        <div
          class="pos-rel viewer-wrap space-bottom-lg img-wrap"
          [ngClass]="{ 'has-file': imageToLoad }"
        >
          @if (!imageToLoad && loadingImageFiles) {
          <mat-progress-bar class="pos-abs" mode="indeterminate"></mat-progress-bar>
          }
          <input
            placeholder="drop image here"
            type="file"
            class="poiner"
            accept="image/png, image/jpeg"
            miltiple="false"
            (change)="onImageFilesChange($event)"
          />
          <div class="dummy disp-flex pos-abs col text-center">
            <img src="/assets/images/picture.svg" alt="image" />
            <div>
              <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
                upload image
              </div>
              <div class="desc2 txt-xxs">
                png, jpeg
              </div>
            </div>
          </div>
          <img [src]="imageToLoad" alt="reference image" class="reference-image">
        </div>
      </div>
      <button
        (click)="texture()"
        type="button"
        mat-raised-button
        class="btn btn-a large w-100 space-bottom-md"
        [disabled]="isDisable()"
      >
        <img
          src="/assets/images/ai-texture.svg"
          alt="ai-texture"
          class="va-text-top"
          [ngClass]="{ 'filter-disabled': isDisable() }"
        />
        Texture 3D model
      </button>
      <app-cost-est [cost]="cost" [est]="120" class="disp-block"></app-cost-est>
    </div>
  }
  </div>
</div>
