import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-privacy-view',
    imports: [CommonModule],
    templateUrl: './privacy-view.component.html',
    styleUrl: './privacy-view.component.scss'
})
export class PrivacyViewComponent {
  @Input() partial: boolean;
  public isloggedIn: boolean
  constructor(
    private auth: AuthService
  ) {
    this.init();
  }

  async init() {
    this.isloggedIn = await this.auth.isloggedIn();
  }
}
