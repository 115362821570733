<div class="wrap disp-flex col">
  <div class="disp-flex space-bottom-md padd-sides">
    <button mat-raised-button aria-label="mobile menu" class="align-end-flex btn btn-c btn-img no-marg-img"
      (click)="close()">
      <img [src]="'/assets/images/close.svg'" alt="menu" class="va-middle" />
    </button>
  </div>

  @if (isloggedIn) {
  <div class="disp-flex space-bottom-md padd-sides">
    <div class="personal pos-rel">
      <button type="button" mat-mini-fab class="btn btn-a account">
        @if (auth.user.avatar_url) {
        <img [src]="auth.user.avatar_url" alt="Avatar" />
        }{{ firstLetter }}
      </button>
    </div>
  </div>
  <div class="space-bottom-md padd-sides pos-rel">
    <div class="ttl-xxs bold">
      {{ auth.user.firstname }}
    </div>
    <div class="txt-sm gray-50-color">
      {{ auth.user.email }}
    </div>
    <button type="button" mat-raised-button class="btn btn-d no-marg-img btn-img notifications pos-abs"
      (click)="showNotifications = !showNotifications" [ngClass]="{ active: showNotifications }">
      <img src="/assets/images/notification.svg" alt="notification" />
      @if (notificationsService.unred) {
      <span class="unred pos-abs">{{notificationsService.unred > 9 ? '+9' : notificationsService.unred}}</span>
      }
    </button>
  </div>
  <mat-divider class="w-100 gray-60"></mat-divider>
  @if (profile) {
  <div class="subscription padd-sides">
    <div class="disp-flex row space-bottom-md">
      <div class="flex-def">Subscription</div>
      <div>
        <span class="sub-label capitalize-fl">
          @if (profile.subDetails) {
          {{ profile.subDetails.name }}
          } @else { free }
        </span>
      </div>
    </div>
    <div class="disp-flex row">
      <div class="flex-def">Available credits</div>
      <div>
        <div class="bold">
          @if (profile.subDetails?.billingCycle) {
          {{ profile.credits | number }}/{{
          profile.subDetails.credits | number
          }}
          }@else {
          {{ profile.credits | number }}/{{ defaultMonthlyCredits }}
          }
        </div>
      </div>
    </div>
    <div>
      <div class="percentage">
        @if (profile.subDetails?.billingCycle) {
        <app-progress-bar [percentage]="100 * (profile.credits / profile.subDetails.credits)"></app-progress-bar>
        } @else {
        <app-progress-bar [percentage]="100 * (profile.credits / defaultMonthlyCredits)"></app-progress-bar>
        }
      </div>
    </div>
  </div>
  }
  <mat-divider class="w-100 gray-60 space-bottom-lg"></mat-divider>
  }
  <div class="disp-flex col padd-sides links">
    @if (isloggedIn) {
    <a [routerLink]="['/generate']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">
      <img src="/assets/images/magic-wang.svg" alt="magic-wang" class="va-text-top" (click)="close()" />
      Generate
    </a>
    <a [routerLink]="['/ai-texture']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">
      <img src="/assets/images/ai-texture.svg" alt="ai-texture" class="va-text-top" />
      AI-texturing
    </a>
    <a [routerLink]="['/creations']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">
      <img src="/assets/images/folder.svg" alt="folder" class="va-text-top" />
      My creations
    </a>
    <a [routerLink]="['/pricing']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">
      <img src="/assets/images/coin-outline.svg" alt="coin" class="va-text-top" />
      Buy credits
    </a>
    <a [routerLink]="['/user-profile']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">
      <img src="/assets/images/user.svg" alt="user" class="va-text-top" />
      My profile
    </a>
    <a class="btn btn-d txt-md err-color" href="javascript:void(0)" (click)="logout()">
      <img src="/assets/images/logout.svg" alt="logout" class="va-text-top" />
      Log out
    </a>
    } @else {
    <a [routerLink]="['/login']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">Login</a>
    <a [routerLink]="['/pricing']" class="btn btn-d txt-md" routerLinkActive="active" (click)="close()">Pricing</a>
    }
  </div>
</div>