import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SimilarItemComponent } from '../similar-item/similar-item.component';
import { ISimilarItem } from '../generate';
import { MatProgressBar } from '@angular/material/progress-bar';
import { GenerateService } from '../generate.service';
import { PixelsService } from '../../shared/pixels.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-similar',
    imports: [SimilarItemComponent, MatProgressBar, MatButtonModule],
    templateUrl: './similar.component.html',
    styleUrl: './similar.component.scss'
})
export class SimilarComponent {
  @Input() items: Array<ISimilarItem>;
  @Output() onSelect: EventEmitter<ISimilarItem>;
  @Output() onClose: EventEmitter<unknown>;
  constructor(
    public gService: GenerateService,
    public pixels: PixelsService
  ) {
    this.onSelect = new EventEmitter<ISimilarItem>();
    this.onClose = new EventEmitter<unknown>();
  }

  select(item: ISimilarItem) {
    this.onSelect.next(item);
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'view_similar_model',
      product_id: item.product_id
    });
  }

  close() {
    this.onClose.next(null);
  }
}
