import { Component, Inject, OnDestroy } from '@angular/core';
import { AiTextureComponent } from '../ai-texture/ai-texture.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAiTuxtureDialogData } from '../generate';
import { transparentDialog } from '../../shared/constants';

@Component({
    selector: 'app-ai-texture-dialog',
    imports: [AiTextureComponent],
    templateUrl: './ai-texture-dialog.component.html',
    styleUrl: './ai-texture-dialog.component.scss'
})
export class AiTuxtureDialogComponent implements OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<AiTuxtureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAiTuxtureDialogData,
  ) {
    document.body.classList.add(transparentDialog);
  }

  ngOnDestroy() {
    document.body.classList.remove(transparentDialog);
  }
}
