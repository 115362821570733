import { Component, Input } from '@angular/core';
import {
  IReconstruct,
  IReconstructImage,
  RECONSTRUCTION_ACTION,
} from '../generate';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { GenerateService } from '../generate.service';
import { MatButtonModule } from '@angular/material/button';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { ScreenNotificationType } from '../../shared/enums';

@Component({
    selector: 'app-view-generated-image',
    imports: [CostEstComponent, MatButtonModule],
    templateUrl: './view-generated-image.component.html',
    styleUrl: './view-generated-image.component.scss'
})
export class ViewGeneratedImageComponent {
  @Input() item: IReconstructImage;
  public price: number;
  constructor(
    private generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.init();
  }

  private async init() {
    this.price = (
      await this.generateService.getAction(RECONSTRUCTION_ACTION.RECONSTRUCTION)
    ).credits;
  }

  async generate() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_preview',
    });
    const payload = {
      action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
      images: [this.item.url],
    } as IReconstruct;
    if (this.item.prompt) payload.text = this.item.prompt;
    const res = await this.generateService.imageTo3D(payload);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'generating . . .',
    });
    this.broadcaster.broadcast('onPublish', res);
  }
}
