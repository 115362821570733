import { Component } from '@angular/core';
import { HexaLogoComponent } from '../shared/hexa-logo/hexa-logo.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    imports: [HexaLogoComponent, RouterLink],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {

}
