import { Component } from '@angular/core';

@Component({
    selector: 'app-or-separator',
    imports: [],
    templateUrl: './or-separator.component.html',
    styleUrl: './or-separator.component.scss'
})
export class OrSeparatorComponent {

}
