import { Component } from '@angular/core';
import { ITopupCardDetails } from '../pricing';
import { EnumsService } from '../../shared/enums.service';
import { TopupsCardComponent } from '../topups-card/topups-card.component';

@Component({
    selector: 'app-topups-view',
    imports: [TopupsCardComponent],
    templateUrl: './topups-view.component.html',
    styleUrl: './topups-view.component.scss'
})
export class TopupsViewComponent {
  public topups: Array<ITopupCardDetails>;
  constructor(private enums: EnumsService) {
    this.topups = this.enums.getAllTopupsOptions();
  }
}
