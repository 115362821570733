<div class="wrap h-100 pos-rel">
  <div class="h-100 disp-flex row inner-wrap">
    <hexa-viewer
      #hexaViewer
      class="w-100"
      embed-loader="true"
      autorotate="true"
      auto-adjust="true"
      [ngClass]="{ 'disp-none': !!glbURL }"
    ></hexa-viewer>
    <div class="disp-flex h-100 col prompt-wrap w-100">
      <div class="disp-flex ip-wrap">
        <span class="txt-xxs bold">Re-texture prompt</span>
        <a
          href="javascript:void(0)"
          class="align-end-flex link txt-xxs bold"
          (click)="examplePrompt()"
          >example prompt</a
        >
      </div>
      <mat-form-field class="w-100 ta-wrap flex-1">
        <textarea
          rows="6"
          [(ngModel)]="text"
          placeholder="Insert re-texture prompt here..."
          matInput
        ></textarea>
      </mat-form-field>
      <div>
        <app-or-separator>or</app-or-separator>
      </div>
      <div class="space-bottom-sm">
        <span class="txt-xxs bold">Upload reference image</span>
      </div>
      <div
          class="pos-rel viewer-wrap space-bottom-lg img-wrap"
          [ngClass]="{ 'has-file': imageToLoad }"
        >
          @if (!imageToLoad && loadingImageFiles) {
          <mat-progress-bar class="pos-abs" mode="indeterminate"></mat-progress-bar>
          }
          <input
            placeholder="drop image here"
            type="file"
            class="poiner"
            accept="image/png, image/jpeg"
            miltiple="false"
            (change)="onImageFilesChange($event)"
          />
          <div class="dummy disp-flex pos-abs col text-center">
            <img src="/assets/images/picture.svg" alt="image" />
            <div>
              <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
                upload image
              </div>
              <div class="desc2 txt-xxs">
                png, jpeg
              </div>
            </div>
          </div>
          <img [src]="imageToLoad" alt="reference image" class="reference-image">
        </div>
      <button
        class="btn btn-a space-bottom-md"
        mat-raised-button
        [disabled]="isDisabled()"
        (click)="texture()"
      >
        <img
          src="/assets/images/ai-texture.svg"
          alt="ai-texture"
          class="va-text-top"
        />
        Re-texture
      </button>
      @if (action) {
      <app-cost-est [cost]="action.credits" [est]="action.estimated_duration"></app-cost-est>
      }
    </div>
  </div>
</div>
