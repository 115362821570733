import {
  AfterContentInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { ISimilarItem } from '../generate';
import { ViewerService } from '../../shared/viewer.service';
import { UtilsService } from '../../shared/utils.service';
import { DecimalPipe } from '@angular/common';
import { AuthService } from '../../auth/auth.service';
import { ViewerControlsComponent } from '../../shared/viewer-controls/viewer-controls.component';
import { SoftwaresService } from '../../shared/softwares.service';
import { Software } from '../../product/product';
import { RestService } from '../../communication/rest.service';
import { ScreenNotificationType } from '../../shared/enums';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
    selector: 'app-similar-preview',
    imports: [
        MatDialogContent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        DecimalPipe,
        ViewerControlsComponent,
    ],
    templateUrl: './similar-preview.component.html',
    styleUrl: './similar-preview.component.scss'
})
export class SimilarPreviewComponent
  implements OnDestroy, AfterContentInit, OnInit
{
  public viewerService: ViewerService;
  public GLBSize: number;
  public softwares: { [id: number]: Software };
  @ViewChild('viewerWrapper') viewerWrapper: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<SimilarPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public item: ISimilarItem,
    private utils: UtilsService,
    public auth: AuthService,
    private ss: SoftwaresService,
    private rest: RestService,
    private broadcaster: BroadcasterService
  ) {}

  async ngOnInit() {
    this.setGLBSize();
    this.softwares = await this.ss.fetch();
  }

  ngAfterContentInit() {
    this.init();
  }

  async init() {
    this.viewerService = new ViewerService(this.utils);
    await this.viewerService.injectScript();
    await this.viewerService.setViewerUrl(this.item.resource_default);
    this.viewerWrapper.nativeElement.appendChild(this.viewerService.element);
  }

  async setGLBSize() {
    // const load = this.utils.getUrlParam(this.item.resource_default, 'load');
    // const domain = this.utils.getDomain(this.item.resource_default);
    // const jsonUrl = `https://${domain}${load}`.toLowerCase();
    // this.GLBSize = await this.utils.getFileSizeByURL(jsonUrl);
    if (this.item.products_resources_files_details?.length)
      this.GLBSize =
        this.item.products_resources_files_details[0].file_size / (1024 * 1024);
  }

  async buy() {
    const job = await this.utils.observableToPromise(
      this.rest.purchaseResource('POST', { product_id: this.item.product_id })
    );
    this.utils.notifyUser({
      type: ScreenNotificationType.Success,
      text: 'Model successfully obtained',
    });
    this.broadcaster.broadcast('onGenerating', job);
  }

  ngOnDestroy() {
    this.viewerService.destroy();
  }
}
