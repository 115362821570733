import { Component } from '@angular/core';

@Component({
    selector: 'app-rights',
    imports: [],
    templateUrl: './rights.component.html',
    styleUrl: './rights.component.scss'
})
export class RightsComponent {

}
