import { Component } from '@angular/core';
import { PreviewComponent } from '../preview/preview.component';
import { UtilsService } from '../utils.service';

@Component({
    selector: 'app-preview-wrap',
    imports: [PreviewComponent],
    templateUrl: './preview-wrap.component.html',
    styleUrl: './preview-wrap.component.scss'
})
export class PreviewWrapComponent {
  public url: string;
  constructor(private utils: UtilsService) {
    this.url = decodeURIComponent(this.utils.getUrlParam(location.href, 'view'));
  }
}
