import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IPricingCardDetails, PricingPeriod, PricingPlan } from '../pricing';
import { PricingCardComponent } from '../pricing-card/pricing-card.component';
import { PaddleService } from '../../payments/paddle.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-pricing-view',
    imports: [PricingCardComponent],
    templateUrl: './pricing-view.component.html',
    styleUrl: './pricing-view.component.scss'
})
export class PricingViewComponent implements OnChanges {
  @Input('pricing-period') pricingPeriod: PricingPeriod;
  public MONTHLY = PricingPeriod.MONTHLY;
  public YEARLY = PricingPeriod.YEARLY;
  public plans: Array<IPricingCardDetails>;

  constructor(
    private auth: AuthService,
    private paddleService: PaddleService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pricingPeriod']) this.updatePlans();
  }

  async updatePlans() {
    const free = {
      type: PricingPlan.NONE,
      name: 'free',
      attributes: [
        'Up to 200 free credits per month',
        'Low quality 3D renders',
        'license for commercial use',
      ],
      desc: 'No credit card needed',
      price: 0,
      isPopular: false,
      priceId: '',
      orgPrice: 0,
      credits: 200,
      isCurrent: !!!this.auth.subscription,
      billingCycle: {
        interval: null,
        frequency: null
      } as any
    };
    const packages = await this.paddleService.getAllCyclicalPackages(
      this.pricingPeriod
    );
    const allPackages = await this.paddleService.getAllCyclicalPackages();
    this.plans = [free];
    packages.forEach((p) => {
      this.plans.push(
        this.paddleService.getCardView(
          allPackages.find((pp) => pp.id === p.id),
          p.prices[0]
        )
      );
    });
    // this.plans = [free].concat(this.enums.getPricingByPeriod(this.pricingPeriod));
  }
}
