import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RestService } from '../../communication/rest.service';
import { ScreenNotificationType } from '../../shared/enums';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-qr-view',
    imports: [MatButtonModule, CommonModule],
    templateUrl: './qr-view.component.html',
    styleUrl: './qr-view.component.scss'
})
export class QrViewComponent implements AfterViewInit {
  public popup: any;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    public dialogRef: MatDialogRef<QrViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.num === 1)
      this.popup = {
        title: 'Can’t scan the QR code?',
        subtitle: '',
        list: [
          'Ensure you have an authentication app on your mobile phone, or scan to download one:',
          'Open the app on your phone and scan the code.',
          'Still having trouble? Contact your account manager.',
        ],
        text1: '',
        text2: '',
        button: '',
        action: () => this.dialogRef.close(),
      };
    else if (data.num === 2)
      this.popup = {
        title: 'Didn’t get a code?',
        subtitle: '',
        list: [
          'Find the code on your authentication app.',
          `Didn’t find it? Get a <a class='qr-link color-link link pointer'>recovery link</a> to reset the QR code, or contact your account manager.`,
        ],
        text1: '',
        text2: '',
        button: '',
        action: () => {
          this.rest
            .userProfile(
              'GET',
              null,
              '/qr?email=' +
                encodeURIComponent(data.email) +
                '&password=' +
                encodeURIComponent(data.password)
            )
            .subscribe(
              () => {
                this.utils.notifyUser({
                  text: 'Recovery email has been sent to ' + data.email,
                  type: ScreenNotificationType.Success,
                  action: 'OK',
                });
              },
              (err: any) => {
                this.utils.getErrorMessage(
                  err,
                  'failure to send recovery email'
                );
              }
            );
        },
      };
  }

  ngAfterViewInit() {
    var ilink = document.querySelector('.qr-link');
    if (ilink) {
      ilink.addEventListener('click', () => {
        this.onClick();
      });
    }
  }

  onClick() {
    this.popup.action();
  }

  close() {
    this.dialogRef.close();
  }
}
